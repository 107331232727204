import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as userActions from '../state/actions/user.actions';
import * as fromRoot from '../state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../state/entity/user';

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  model: User = new User();
  destroy$: Subject<boolean> = new Subject<boolean>();
  ionicForm: FormGroup;

  constructor(public formBuilder: FormBuilder, private router: Router, private readonly store: Store) {
    this.ionicForm = formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['']
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    this.model.email = this.ionicForm.get('email').value;
    this.model.password = this.ionicForm.get('password').value;
    this.store.dispatch(userActions.login({user: { email: this.model.email, password: this.model.password }}));
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
