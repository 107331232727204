import { getLoggedInUser } from './state/index';
import { Component, OnInit } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { User } from './state/entity/user';
import { Store } from '@ngrx/store';
import * as userActions from './state/actions/user.actions';
import * as fromRoot from './state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Agenda', url: '/agenda', icon: 'calendar' },
    { title: 'Pacientes', url: '/pacientes', icon: 'body' },
    { title: 'Acupuntura', url: '/acupuntura', icon: 'file-tray-full' },
    { title: 'Auriculoterapia', url: '/auriculoterapia', icon: 'file-tray-full' },
    { title: 'Radiestesia', url: '/radiestesia', icon: 'file-tray-full' },
    { title: 'Reiki', url: '/reiki', icon: 'file-tray-full' },
    { title: 'Thetahealing', url: '/thetahealing', icon: 'file-tray-full' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  model: User = null;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.select(fromRoot.userLogin).subscribe(data => {
      console.log('data::::', data);
      if (data.user?.email !== undefined) {
        this.model = data.user;
      }
    });
  }

}
